* {
  font-family: 'Poppins', sans-serif;
}

.ChevronRight__Transition {
  transition: all 0.3s ease;
}

.ChevronRight__Transition.ChevronRight__Open {
  transform: rotate(90deg);
}

.GoToCartButton:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px #74b0cc;
  transition: all 0.3s ease 0s;
  transform: translateY(-7px);
}

.NewSaleButton:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  transition: all 0.3s ease 0s;
  transform: translateY(-7px);
}

::-webkit-scrollbar {
  height: 16px;
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border: 5px solid transparent;
  border-radius: 20px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
